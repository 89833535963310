import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomCookieService } from '@uc/shared/authentication';
import { EnvironmentApiService } from '@uc/utilities';
import { ProductHandlerService } from '@uc/web/core';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ClearingVisitWebsiteBtnApiService {
	private _clearingUserTrackingUrl = 'Users/cl_app_user_visit_website.php';
	private _clearingGuestTrackingUrl = 'Users/cl_app_guest_visit_website.php';

	constructor(
		private _http: HttpClient,
		private _customCookieSrv: CustomCookieService,
		private _productHandlerSrv: ProductHandlerService,
		private _apiSrv: EnvironmentApiService,
	) {}

	/**
	 * @param uniId ID of uni tracked
	 * @param source name of uni or course name
	 * @param sourceType uni | course
	 * @returns int (I assume ID of last inserted row in DB)
	 */
	clearingVisitWebsiteClick(
		uniId: number,
		source: string,
		sourceType: SourceTrackingTypes = 'uni',
	) {
		const userId = this._customCookieSrv.get('user_id');
		const userIdKey = userId ? 'id' : 'gid';
		const url = this._getPhpUrl(userIdKey);
		const params = {
			uid: uniId,
			id: userId ? userId : 0,
			source: this._productHandlerSrv.configureSource(source, sourceType),
		};

		lastValueFrom(this._http.post(this._apiSrv.PHP_URL + url, params)).then(
			console.log,
		);
	}

	private _getPhpUrl(userId: string | null) {
		return userId ? this._clearingUserTrackingUrl : this._clearingGuestTrackingUrl;
	}
}
