<div class="grid grid-cols-1 gap-6 mt-6">
	<!-- social media-->
	<div class="flex space-x-6">
		<a
			href="https://www.tiktok.com/@unicompare?_t=8YAQqjCVEqD&_r=1"
			target="_blank"
			rel="noopener"
			class="text-gray-400 hover:text-gray-500"
		>
			<span class="sr-only">TikTok</span>
			<uc-tiktok-svg />
		</a>

		<a
			href="https://www.linkedin.com/company/unicompare/"
			target="_blank"
			rel="noopener"
			class="text-gray-400 hover:text-gray-500"
		>
			<span class="sr-only">LinkedIn</span>
			<uc-linkedin-footer-svg />
		</a>
	</div>

	<!-- download app -->
	<div class="flex space-x-3">
		<a
			href="https://apps.apple.com/gb/app/uni-compare/id1100270774"
			target="_blank"
			rel="noopener"
			title="Download our iOS app"
			class="flex-shrink-0"
			(click)="onDownloadAppClick()"
		>
			<span class="sr-only">Download iOS app</span>
			<img
				[ngSrc]="'/assets/img/download-ios.svg' | cdn"
				alt="Apple Store Logo"
				width="120"
				height="40"
			/>
		</a>

		<a
			href="https://play.google.com/store/apps/details?id=com.uniltd.unicompare&hl=en&gl=US"
			target="_blank"
			rel="noopener"
			title="Download our Android app"
			(click)="onDownloadAppClick()"
		>
			<span class="sr-only">Download Android app</span>
			<img
				[ngSrc]="'/assets/img/google-play-badge.png' | cdn"
				alt="Google Play Logo"
				width="150"
				height="60"
				class="-mt-[0.6rem]"
			/>
		</a>
	</div>
</div>
