import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { SearchComponent } from '@uc/shared/ui';
import { Product } from '@uc/web/shared/data-models';
import { InAppBrowserSearchBarComponent } from '../in-app-browser-search-bar/in-app-browser-search-bar.component';
import { capitaliseFirstLetter } from '@uc/utilities';

@Component({
	selector: 'uc-course-search-banner',
	standalone: true,
	templateUrl: './course-search-banner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SearchComponent, InAppBrowserSearchBarComponent],
})
export class CourseSearchBannerComponent {
	product = input<Product>('undergraduate');
	customHeading = input<string>('');
	description = input<string>('');
	uniName = input<string>('');
	uniSlug = input<string>('');
	openSearchInNewTab = input<boolean>(false);
	isInAppBrowser = input<boolean>(false);

	heading = computed(() => this._generateHeading());

	private _generateHeading(): string {
		return (
			this.customHeading() ||
			`Search ${capitaliseFirstLetter(this.product())} Courses at ${this.uniName()}`
		);
	}
}
