<!-- mobile -->
<a
	[href]="link"
	(click)="onDownloadAppClick()"
	class="inline-block w-full md:w-auto lg:hidden"
	[ngClass]="customStyles"
	target="_blank"
	rel="noopener"
	data-test-id="download-app-btn-mobile"
	>{{ buttonText }}</a
>

<!-- desktop -->
<button
	class="hidden blue-button lg:block"
	[ngClass]="customStyles"
	(click)="openModal(); onDownloadAppClick()"
	data-test-id="download-app-btn-desktop"
>
	{{ buttonText }}
</button>
