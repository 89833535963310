import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
	CategoryNamePipe,
	CdnPathPipe,
	EntitiesPipe,
	SafePipe,
	UcDatePipe,
} from '@uc/utilities';
import { LinkProcessorService } from '@uc/web/core';
import { ShareArticleComponent } from './components/share-article/share-article.component';
import { SideCardComponent } from './components/side-card/side-card.component';
import { LoadingAnimationComponent } from '@uc/shared/ui';
import { HeartSvgComponent } from '@uc/shared/svg';
import { PromoSlotCtaCardComponent } from '@uc/web/shared/feature/feature-promo-slots';

@Component({
	selector: 'uc-article-container',
	standalone: true,
	templateUrl: './article-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		SafePipe,
		UcDatePipe,
		EntitiesPipe,
		CdnPathPipe,
		CategoryNamePipe,
		LoadingAnimationComponent,
		ShareArticleComponent,
		SideCardComponent,
		RouterModule,
		CommonModule,
		HeartSvgComponent,
		PromoSlotCtaCardComponent,
	],
})
export class ArticleContainerComponent {
	@Input() loadingAnimation = '';
	@Input() loadingArticle = false;
	@Input() article: any;
	@Input() categoryKey = '';
	@Input() articleCategoryUrl = '';
	@Input() featuredArticles: any;
	@Input() articleCategory = '';
	@Input() url = '';
	@Input() guideCategoryLongName = '';

	constructor(private linkProcessorSrv: LinkProcessorService) {}

	processLinks = (e: Event) => this.linkProcessorSrv.processLinks(e);
}
