import { createAction, props } from '@ngrx/store';
import { PromoSlot } from '@uc/web/shared/data-models';

export const fetchPromoSlots = createAction('[Promo Slots] fetch promo slots');

export const updatePromoSlots = createAction(
	'[Promo Slots] update promo slots',
	props<{ defaultSlots: PromoSlot[]; cleSlots: PromoSlot[] }>(),
);

export const loadPromoSlotsError = createAction(
	'[Promo Slots] update promo slots error',
	props<{ error: any }>(),
);
