import { createReducer, on, Action } from '@ngrx/store';

import * as StateRecommendedUniversitiesActions from './recommended-universities.actions';
import { RecommendedUnisCollection } from '@uc/web/shared/data-models';
// import { updateSavedInStore } from '@web/shared/ui';

export const _STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY = 'stateRecommendedUniversities';

export interface RecommendedUniversitiesState {
	recommendedUniversities: RecommendedUnisCollection | null;
	loaded: boolean; // has the StateRecommendedUniversities list been loaded
	error?: string | null; // last known error (if any)
}

export interface StateRecommendedUniversitiesPartialState {
	readonly [_STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY]: RecommendedUniversitiesState;
}

export const recommendedUniversitiesInitialState: RecommendedUniversitiesState = {
	// set initial required properties
	recommendedUniversities: null,
	loaded: false,
};

const stateRecommendedUniversitiesReducer = createReducer(
	recommendedUniversitiesInitialState,
	on(StateRecommendedUniversitiesActions.fetchRecommendedUniversities, (state) => ({
		...state,
		error: null,
	})),
	on(
		StateRecommendedUniversitiesActions.updateRecommendedUniversities,
		(state, { universities }) => ({
			...state,
			recommendedUniversities: {
				...state.recommendedUniversities,
				...universities,
			},
			markedForFetch: false,
			loaded: true,
			error: null,
		}),
	),
	// TODO: part of refactoring - circular dependency issue
	// on(updateSavedInStore, (state, { uniId }) => ({
	// 	...state,
	// 	recommendedUniversities: updateSavedProperty(
	// 		state.recommendedUniversities,
	// 		uniId
	// 	),
	// 	loaded: true,
	// 	error: null,
	// })),
	on(
		StateRecommendedUniversitiesActions.loadStateRecommendedUniversitiesFailure,
		(state, { error }) => ({
			...state,
			error,
		}),
	),
);

export function recommendedUniversitiesReducer(
	state: RecommendedUniversitiesState | undefined,
	action: Action,
) {
	return stateRecommendedUniversitiesReducer(state, action);
}

// function updateSavedProperty(
// 	recommendedUniversities: RecommendedUnisCollection | null,
// 	uniId: number,
// ) {
// 	if (!recommendedUniversities) return recommendedUniversities;

// 	for (const product in recommendedUniversities) {
// 		const key = product as keyof RecommendedUnisCollection;
// 		recommendedUniversities[key].forEach((uni: RecommendedUniversity) => {
// 			if (uni.id === uniId) {
// 				uni.saved = !uni.saved;
// 			}
// 		});
// 	}

// 	return recommendedUniversities;
// }
