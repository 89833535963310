import { Action, createReducer, on } from '@ngrx/store';
import * as fromPromoSlotsActions from './promo-slots.actions';
import { PromoSlot } from '@uc/web/shared/data-models';

export const _STATE_PROMO_SLOTS_FEATURE_KEY = 'statePromoSlots';

export interface State {
	defaultPromoSlots: PromoSlot[] | null;
	clearingPromoSlots: PromoSlot[] | null;
	loading: boolean;
	error: any;
}

export const initialState: State = {
	defaultPromoSlots: null,
	clearingPromoSlots: null,
	loading: false,
	error: '',
};

const reducer = createReducer(
	initialState,
	on(fromPromoSlotsActions.loadPromoSlotsError, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),
	on(fromPromoSlotsActions.fetchPromoSlots, (state) => ({
		...state,
		error: '',
		loading: true,
	})),
	on(fromPromoSlotsActions.updatePromoSlots, (state, { defaultSlots, cleSlots }) => ({
		...state,
		defaultPromoSlots: defaultSlots,
		clearingPromoSlots: cleSlots,
		error: '',
		loading: false,
	})),
);

export function promoSlotsReducer(state: State | undefined, action: Action) {
	return reducer(state, action);
}
