import { PromoSlot } from '@uc/web/shared/data-models';

export const addContentToArrays = (
	content: PromoSlot[],
	iterator: number,
	batch: PromoSlot[],
) => {
	if (!content || content.length === 0) return;

	for (let i = iterator; batch.length < 4; i++) {
		if (i >= content.length) {
			i = -1;
			continue;
		}

		if (content[i] !== undefined) {
			batch.push(content[i]);
		}
	}
};

export const getRandomPromoSlot = (content: PromoSlot[] | null): null | PromoSlot => {
	if (!content || content?.length === 0) return null;
	const randomIndex = Math.floor(Math.random() * content.length);

	return {
		...content[randomIndex],
		index: randomIndex,
	};
};
