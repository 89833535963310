import { ChangeDetectionStrategy, Component, input, OnInit, signal } from '@angular/core';
import { Product } from '@uc/web/shared/data-models';
import { BookmarkModel } from './bookmark.models';

import { BookmarkBtnComponent } from './components/bookmark-btn/bookmark-btn.component';
import { BookmarkHeartIconComponent } from './components/bookmark-heart-icon/bookmark-heart-icon.component';
import { BookmarkService } from './bookmark.service';

export enum BookmarkType {
	University,
	Course,
}

@Component({
	selector: 'uc-bookmark',
	standalone: true,
	templateUrl: './bookmark.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [BookmarkBtnComponent, BookmarkHeartIconComponent],
})
export class BookmarkComponent implements OnInit {
	academicYear = input<number>();
	ugSearchOrderPosition = input<number | null>(null);
	saved = input.required({
		transform: (value: number | boolean | undefined) => !!value,
	});
	id = input<number | undefined>();
	uniName = input<string>('');
	mobileRender = input<'default'>();
	//if desktop btn style should be shown on mobile too set to true
	showDesktopOnMobile = input<boolean>();
	courseUniProfileMobile = input<boolean>(false);
	/**
	 * @delta takes the values 1 | 2
	 * 1 = save university
	 * 2 = save course
	 */
	delta = input.required<1 | 2>();
	svgStyles = input<string>('h-6 w-2.5');
	/**
	 * @componentName is used for the analytics
	 */
	componentName = input<string | null>(null);
	/**
	 * @product is used for the analytics
	 */
	product = input<Product | ''>('');
	bookmarkBtn = input<boolean>();
	bookmarkHeartIcon = input<boolean>();
	courseSlug = input<string>('');

	isSaved = signal(false);

	constructor(private _bookmarkSrv: BookmarkService) {}

	ngOnInit(): void {
		this.isSaved.set(this.saved());
	}

	onBookmark(saved: boolean) {
		const params: BookmarkModel = {
			id: this.id(),
			uniName: this.uniName(),
			delta: this.delta(),
			saved: this.isSaved(),
			componentName: this.componentName(),
			product: this.product(),
			ugSearchOrderPosition: this.ugSearchOrderPosition(),
			courseSlug: this.courseSlug(),
			year: this.academicYear(),
		};

		this.isSaved.set(saved);

		this._bookmarkSrv.bookmarkHandler(params).then((response) => {
			if (response === false) {
				this.isSaved.set(!this.isSaved());
			}
		});
	}
}
