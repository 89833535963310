export const getUniCourseSearchParams = (url: string) => {
	const res: { [key: string]: string } = {};

	const params = url.split('?')[1];

	const keyValuePairs = params?.split('&');
	keyValuePairs?.forEach((pair) => {
		const [key, value] = pair.split('=');
		if (key && value) {
			res[key] = value;
		}
	});

	if (res['loc']) {
		delete res['loc'];
	}

	if (res['utype']) {
		delete res['utype'];
	}

	return res;
};
