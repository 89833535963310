<footer class="bg-uc-blue-950">
	<div class="container py-16">
		<div class="lg:grid lg:grid-cols-3 xl:gap-8">
			<div class="">
				<h3 class="font-semibold text-white uppercase text-md">Uni Compare</h3>
				<p class="pr-6 mt-4 mr-6 text-gray-300 text-md">
					We believe everyone should have the choice to find the right course at
					the right university.
				</p>

				<uc-social-and-app-cta />
			</div>
			<div class="grid grid-cols-2 gap-8 mt-12 lg:mt-0 lg:col-span-2">
				<div class="md:grid md:grid-cols-2">
					<div>
						<h3 class="font-semibold text-white uppercase text-md">Study</h3>
						<ul class="mt-4 space-y-3 text-md">
							<li>
								<a
									routerLink="/courses/undergraduate"
									class="text-gray-300 hover:text-gray-100"
								>
									Courses
								</a>
							</li>

							<li>
								<a
									routerLink="/universities"
									class="text-gray-300 hover:text-gray-100"
								>
									Universities
								</a>
							</li>

							<li>
								<a
									routerLink="/open-days"
									class="text-gray-300 hover:text-gray-100"
								>
									Open Days
								</a>
							</li>
						</ul>
					</div>

					<div class="mt-12 md:mt-0 md:-ml-3">
						<h3 class="font-semibold text-white uppercase text-md">
							Student
						</h3>
						<ul class="mt-4 space-y-3 text-md">
							<li>
								<a
									routerLink="/personal-statement-examples"
									class="text-gray-300 hover:text-gray-100"
								>
									Statement Examples
								</a>
							</li>

							<li>
								<a
									routerLink="/rankings"
									class="text-gray-300 hover:text-gray-100"
								>
									University Rankings
								</a>
							</li>

							<li>
								<a
									routerLink="/advice"
									class="text-gray-300 hover:text-gray-100"
								>
									Student Advice
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div class="md:grid md:grid-cols-2 md:gap-8">
					<div>
						<h3 class="font-semibold text-white uppercase text-md">
							Account
						</h3>
						<ul class="mt-4 space-y-3 text-md">
							<li>
								<a
									(click)="onLoginClick()"
									routerLink="/login"
									class="text-gray-300 hover:text-gray-100"
								>
									Sign In
								</a>
							</li>

							<li>
								<a
									(click)="onRegisterClick()"
									routerLink="/register"
									class="text-gray-300 hover:text-gray-100"
								>
									Register
								</a>
							</li>

							<li>
								<a
									routerLink="/password"
									class="text-gray-300 hover:text-gray-100"
								>
									Password
								</a>
							</li>
						</ul>
					</div>

					<div class="mt-12 md:mt-0">
						<h3 class="font-semibold text-white uppercase text-md">
							Company
						</h3>
						<ul class="mt-4 space-y-3 text-md">
							<li>
								<a
									routerLink="/about"
									class="text-gray-300 hover:text-gray-100"
								>
									About
								</a>
							</li>

							<li>
								<a
									routerLink="/advertise"
									class="text-gray-300 hover:text-gray-100"
								>
									Advertise
								</a>
							</li>

							<li>
								<a
									routerLink="/contact"
									class="text-gray-300 hover:text-gray-100"
								>
									Contact
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div
			class="flex flex-wrap-reverse gap-4 pt-8 mt-12 border-t border-white border-opacity-5"
		>
			<p class="row-start-2 text-gray-300 xl:text-left">
				&copy;{{ currentYear }} Uni Compare Ltd. All rights reserved.
			</p>
			<ul class="flex flex-row space-x-4 text-md">
				<li>
					<a routerLink="/privacy" class="text-gray-300 hover:text-gray-100">
						Privacy
					</a>
				</li>
				<li>
					<a routerLink="/terms" class="text-gray-300 hover:text-gray-100">
						Terms
					</a>
				</li>
				<li>
					<a
						routerLink="/advertising-terms"
						class="text-gray-300 hover:text-gray-100"
					>
						Advertising Terms
					</a>
				</li>
				<li>
					<a routerLink="/sitemap" class="text-gray-300 hover:text-gray-100">
						Sitemap
					</a>
				</li>
				<li>
					<div
						id="ccpa"
						style="display: none; cursor: pointer"
						class="text-gray-300 hover:text-gray-100"
					>
						Do not share my Personal Information.
					</div>
				</li>
			</ul>
		</div>
	</div>
</footer>
