@if (promoSlot()) {
	<div
		(click)="onClick()"
		class="block p-3 border cursor-pointer bg-uc-blue-50 border-uc-blue-200 rounded-2xl"
		data-test-id="promo-slot-cta-card"
	>
		<span class="block mb-3 text-sm font-medium leading-5 text-uc-blue-900"
			>Recommended University</span
		>
		@if (promoSlot()?.image) {
			<span class="relative block w-full mb-5 h-52">
				<img
					class="object-cover rounded-2xl"
					[ngSrc]="promoSlot()?.image!"
					alt=""
					fill
				/>
			</span>
		}
		<span class="block mb-1 text-lg font-bold leading-7 text-uc-blue-900">{{
			promoSlot()?.title
		}}</span>
		<span class="block text-base font-normal leading-6 text-gray-500">{{
			promoSlot()?.description
		}}</span>
	</div>
}
