<article class="divider" [ngClass]="customStyles">
	<uc-heading [headingTitle]="heading" />
	<section class="p-6 pt-8 mt-6 border rounded-lg">
		<ng-content />
		@for (question of faq; track $index; let first = $first; let last = $last) {
			<div
				ucToggleFaq
				class="flex items-center justify-between border-t cursor-pointer only-tab-focus"
				tabindex="0"
				[ngClass]="{
					'py-6': !first && !last,
					'pb-6': !ngContentInserted && first,
					'pt-6': last || (ngContentInserted && first),
					'!border-t-0': first,
				}"
			>
				<h3 class="text-base font-medium pointer-events-none md:text-lg">
					{{ question.question }}
				</h3>
				<div class="pointer-events-none">
					<uc-arrow-down-svg customStyles="!w-6 !h-6 ml-1" />
					<uc-arrow-up-svg customStyles="hidden" />
				</div>
			</div>
			<!-- answer -->
			<div [ngClass]="{ 'pt-6': last, 'pb-6': !last }" class="sr-only faqs">
				<div
					(click)="processLinks($event)"
					class="space-y-4 text-base font-normal text-gray-500"
					[innerHTML]="question.answer"
					[ngClass]="{ 'mb-4': question.external_url }"
				></div>

				<!-- 
				dynamically insert visit-website-btn / clearing-visit-website-btn 
				(currently not used in the project as profile FAQs are commented out)
				-->
				@if (question.external_url && visitWebsiteBtnRef) {
					<ng-container
						[ngTemplateOutlet]="visitWebsiteBtnRef"
						[ngTemplateOutletContext]="{
							$implicit: { question },
						}"
					>
					</ng-container>
				}
			</div>
		}
	</section>
</article>
