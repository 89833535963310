import { Injectable } from '@angular/core';
import { VisitWebsiteGTMService } from '@uc/web/core';
import { ClearingVisitWebsiteBtnApiService } from '@uc/web/shared/data-access';
import { ProductEnum, SourceTrackingTypes } from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class ClearingVisitWebsiteBtnService {
	constructor(
		private _visitWebsiteGTMSrv: VisitWebsiteGTMService,
		private _clearingVisitWebsiteApiSrv: ClearingVisitWebsiteBtnApiService,
	) {}

	onClearingVisitWebsiteClick(
		uniId: number | null,
		uniName: string,
		componentName: string,
		cleSearchOrderPosition: number | null,
		source: string,
		sourceType: SourceTrackingTypes = '',
		trackingEnabled = true,
	) {
		if (uniId) {
			this._visitWebsiteGTMSrv.visitWebsiteClick(
				uniId,
				uniName,
				componentName,
				cleSearchOrderPosition, //todo-cleso
				ProductEnum.Clearing,
			);

			if (trackingEnabled) {
				this._clearingVisitWebsiteApiSrv.clearingVisitWebsiteClick(
					uniId,
					source,
					sourceType,
				);
			}
		}
	}
}
