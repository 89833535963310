<section class="bg-white divider">
	<div class="flex flex-col">
		@if (data) {
			<div class="relative overflow-hidden">
				<!-- cover photo with link -->
				<!-- Desktop -->
				<div class="hidden h-full md:flex">
					<button
						(click)="showModal(); onDownLoadAppClick()"
						data-test-id="image-button-desktop"
						class="hidden md:flex"
					>
						<uc-engage-card-cta-img
							[backgroundImage]="data.backgroundImage | cdn"
							[border]="true"
						/>
					</button>
				</div>

				<!-- cover photo with link -->
				<a
					(click)="onDownLoadAppClick()"
					[attr.href]="data.link"
					target="_blank"
					rel="noopener sponsored"
					class="md:hidden"
					data-test-id="image-link-mobile"
				>
					<uc-engage-card-cta-img
						[backgroundImage]="data.backgroundImage | cdn"
						[border]="true"
					/>
				</a>

				<div class="relative pt-6 mx-auto md:py-12 max-w-7xl">
					<div class="lg:px-5 md:ml-auto md:w-1/2 md:pl-8 lg:pl-16">
						<!-- icon & uppercase icon text -->

						<div class="flex items-center gap-3">
							<img
								[ngSrc]="'/assets/img/fire.svg' | cdn"
								width="20"
								height="20"
								alt=""
							/>
							<p class="font-semibold text-gray-500 uppercase">
								{{ data.iconText }}
							</p>
						</div>

						<!-- title -->
						<p class="flex flex-wrap-reverse mt-2 tracking-tight">
							<!-- Desktop -->
							<button
								(click)="showModal(); onDownLoadAppClick()"
								class="hidden text-2xl font-bold md:flex"
								data-test-id="title-button-desktop"
							>
								{{ data.title }}
							</button>

							<!-- Mobile -->
							<a
								(click)="onDownLoadAppClick()"
								class="text-2xl font-bold md:hidden"
								[attr.href]="data.link"
								target="_blank"
								rel="noopener sponsored"
								data-test-id="title-link-mobile"
							>
								{{ data.title }}
							</a>
						</p>

						<!-- description -->
						<p class="mt-4 text-base text-gray-500">
							{{ data.description }}
						</p>

						<!-- CTA button -->
						<div class="mt-8">
							<uc-download-app-btn
								[link]="data.link"
								[buttonText]="data.buttonText"
								[product]="product()"
							/>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
</section>
