<section class="py-8 bg-gray-800 sm:py-16">
	<div class="container flex flex-col text-white sm:items-center sm:text-center">
		<h2
			class="text-xl font-semibold font-xl sm:text-4xl"
			data-test-id="course-search-banner-heading"
		>
			{{ heading() }}
		</h2>
		<p class="pt-2 text-base text-gray-200 sm:pt-5 sm:text-xl">{{ description() }}</p>
		@if (isInAppBrowser()) {
			<uc-in-app-browser-search-bar class="w-full mt-8 sm:max-w-2xl" />
		} @else {
			<uc-search-bar
				class="w-full mt-8 sm:max-w-2xl"
				placeholder="Search Courses"
				buttonStyles="font-bold bg-white text-uc-blue-900 border-l-2 border-l-uc-blue-900 hover:bg-gray-100"
				[redirectTo]="'courses/' + product()"
				[displayButton]="true"
				[uniSlug]="uniSlug()"
				[openSearchInNewTab]="openSearchInNewTab()"
			/>
		}
	</div>
</section>
