import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginGTMService, RegisterGTMService } from '@uc/web/core';
import { SocialAndAppCtaComponent } from './social-and-app-cta/social-and-app-cta.component';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-footer',
	standalone: true,
	templateUrl: './footer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SocialAndAppCtaComponent, RouterModule],
})
export class FooterComponent {
	currentYear = new Date().getFullYear();
	private readonly _componentName = 'Nav';

	constructor(
		private _loginGTMSrv: LoginGTMService,
		private _registerGTMSrv: RegisterGTMService,
	) {}

	// GTM event
	onLoginClick = () => this._loginGTMSrv.loginClick();
	onRegisterClick = () => this._registerGTMSrv.registerClick(this._componentName);
}
