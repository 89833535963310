<!-- This button appears on the course/uni profile page desktop view-->
@if (bookmarkBtn()) {
	<uc-bookmark-btn [saved]="isSaved()" [delta]="delta()" (save)="onBookmark($event)" />
}

<!-- This button icon appears on the course/uni profile page mobile view -->
@if (bookmarkHeartIcon()) {
	<uc-bookmark-heart-icon
		[saved]="isSaved()"
		[delta]="delta()"
		(save)="onBookmark($event)"
	/>
}
