import { createFeatureSelector, createSelector } from '@ngrx/store';
import { _STATE_PROMO_SLOTS_FEATURE_KEY, State } from './promo-slots.reducer';

export const getPromoSlotsState = createFeatureSelector<State>(
	_STATE_PROMO_SLOTS_FEATURE_KEY,
);

export const getPromoSlotsData = createSelector(getPromoSlotsState, (state: State) => ({
	default: state.defaultPromoSlots,
	clearing: state.clearingPromoSlots,
}));

export const getDefaultPromoSlotsData = createSelector(
	getPromoSlotsState,
	(state: State) => state.defaultPromoSlots,
);

export const getClePromoSlotsData = createSelector(
	getPromoSlotsState,
	(state: State) => state.clearingPromoSlots,
);
