import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Product, ClientFeatureOptions, Gallery } from '@uc/web/shared/data-models';
import { BookmarkComponent } from '../bookmark/bookmark.component';
import { UniversityGalleryComponent } from '../university-gallery/university-gallery.component';
import { JsonPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-university-header',
	standalone: true,
	templateUrl: './university-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		NgClass,
		JsonPipe,
		NgOptimizedImage,
		BookmarkComponent,
		UniversityGalleryComponent,
	],
})
export class UniversityHeaderComponent {
	universityGallery = input.required<Gallery>();
	paidFeatures = input.required<ClientFeatureOptions[]>();
	logo = input.required<string>();
	uniName = input.required<string>();
	product = input.required<Product>();
	saved = input<number | undefined | boolean>();
	id = input<number | undefined>(); // University ID | Course ID
	page = input<string>('university');
	currentClearingYear = input<number>();
	/*
	 * delta takes the values 1 | 2
	 * 1 = save university
	 * 2 = save course
	 */
	delta = input.required<1 | 2>();
	courseSlug = input<string>('');
	isLoaded = input<boolean>();
	ugSearchOrderPosition = input<number | null>(null);
	academicYear = input<number>();
}
