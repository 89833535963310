export const clearingCopy = {
	backgroundImage: '/assets/img/Clearing_App_Mockup.svg',
	link: 'https://onelink.to/clearing',
	iconText: 'CHECK OUT OUR APP',
	title: 'Download Our Clearing App',
	description:
		"Your UCAS Clearing search doesn't have to end here. Take it on the go with our Clearing App. Easily search and enquire about Clearing courses and call universities right from the app.",
	buttonText: 'Download our Clearing App',
};

export const copy = {
	backgroundImage: '/assets/img/Download_UC_CTA_Web_01.png',
	link: 'https://onelink.to/unicompare',
	iconText: 'DOWNLOAD THE APP',
	title: 'Take your uni search on the go!',
	description:
		'Any time, any place, compare universities, search courses and book open days. Download the Uni Compare app to keep searching.',
	buttonText: 'Download The App',
};
